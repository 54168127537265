import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'routes';
import {
  getSelectedEvents,
  updateAggEventStatus,
  getResultsByRound,
  getResultsByEventId,
  getHighJumpResults,
  getHighJumpResultsByEventId,
} from 'api/resultApi';
import { getMeetById, getClubRecords } from 'api/clubApi';
import { convertDateTime, convertMillisecondsToRecord } from 'utils/time';
import { getClubRole } from 'services/auth/tokenService';
import {
  resultsToTableData,
  getAgeGroupsForClubRecords,
} from 'services/admin/ResultService';
import styles from './Results.module.css';
import MetaTags from 'components/common/MetaTags';
import AdminPageHeader from 'components/admin/AdminPageHeader';
import AdminTablePageLayout from 'components/admin/AdminTablePageLayout';
import ResultEditPopup from 'components/admin/ResultEditPopup';
import ResultDataTable from 'components/admin/result/ResultDataTable';
import ResultPageOptionButton from 'components/admin/result/ResultPageOptionButton';
import { useDispatch, useSelector } from 'react-redux';
import { eventStatusUpdate } from 'services/admin/Event/eventSlice';
import { getRoundsOptions } from 'services/admin/EventService';

const Results = ({ isOpenEditPopup = false }) => {
  const navigate = useNavigate();
  const clubRole = getClubRole();
  const dispatch = useDispatch();

  const { clubId, eventId, meetId, eventType, ageGroup, gender } = useParams();

  const [showEditPopup, setShowEditPopup] = React.useState(isOpenEditPopup);
  const [isPopupReady, setIsPopupReady] = useState(false);

  const [, setFetchingResults] = useState(true);
  const [fetchingEvent, setFetchingEvent] = useState(true);
  const [fetchingMeet, setFetchingMeet] = useState(true);
  const [fetchingClubRecords, setFetchingClubRecords] = useState(true);
  const [clubRecords, setClubRecords] = useState([]);
  const [roundTypeOptions, setRoundTypeOptions] = useState([]);
  const [roundType, setRoundType] = useState('');

  const [meet, setMeet] = useState(null);
  const [, setEvent] = useState(null);
  const [results, setResults] = useState([]);
  const [resultType, setResultType] = useState('');
  const [optionValues, setOptionValues] = useState({ dataType: 'all' });
  const { selectedEvent } = useSelector((state) => state.event || null);

  useEffect(() => {
    if (!selectedEvent) {
      return navigate(routes.admin.events.url(clubId, meetId));
    }
    setRoundTypeOptions(getRoundsOptions(selectedEvent.rounds));
  }, [clubId, meetId, navigate, selectedEvent]);

  const fetchResults = useCallback(async () => {
    try {
      if (!roundTypeOptions?.length) {
        return;
      }
      setFetchingResults(true);
      const roundTypeOption = roundTypeOptions[0];
      setOptionValues({ dataType: roundTypeOption.value });
      let results = [];
      setRoundType(roundTypeOption.type);
      if (eventType === 'High Jump') {
        results = await getHighJumpResults(
          clubId,
          meetId,
          eventType,
          ageGroup,
          gender,
          roundTypeOption.type
        );
      } else {
        results = await getResultsByRound(
          clubId,
          meetId,
          eventType,
          ageGroup,
          gender,
          roundTypeOption.type
        );
      }
      setResults(resultsToTableData(results, eventType));
    } catch (err) {
      console.error('[Results] Fetching results Error: ', err);
    } finally {
      setFetchingResults(false);
    }
  }, [clubId, meetId, eventType, ageGroup, gender, roundTypeOptions]);

  const fetchEvent = useCallback(async () => {
    try {
      const response = await getSelectedEvents(clubId, meetId, [eventId]);
      setEvent(response.data[0]);
    } catch (err) {
      console.log('[Results] Fetch Event error: ', err);
    }
  }, [clubId, meetId, eventId]);

  const fetchMeet = useCallback(async () => {
    try {
      const response = await getMeetById(clubId, meetId);

      setMeet(response.data);
    } catch (err) {
      console.log('[Results] Fetch Meets error: ', err);
    }
  }, [clubId, meetId]);

  const fetchClubRecords = useCallback(async () => {
    if (eventType !== 'Mixed' && ageGroup !== 'Mixed' && gender !== 'Mixed') {
      const response = await getClubRecords(
        clubId,
        eventType,
        getAgeGroupsForClubRecords(ageGroup),
        gender
      ).catch((e) => {
        console.error(e); // "No club record found for the specified criteria."
        return null;
      });
      if (response) {
        console.log('club records: ', response.data);
        setClubRecords(response.data);
      }
    }
  }, [clubId, eventType, ageGroup, gender]);

  const openEditPopup = () => {
    setShowEditPopup(true);
    navigate(
      routes.admin.editResults.url(
        clubId,
        meetId,
        eventType,
        ageGroup,
        gender,
        roundType
      )
    );
  };

  const closeEditPopup = () => {
    setShowEditPopup(false);
    navigate(
      routes.admin.results.url(
        clubId,
        meetId,
        eventType,
        ageGroup,
        gender,
        roundType
      )
    );
  };

  useEffect(() => {
    if (results.length > 0) {
      setIsPopupReady(true);
    }
  }, [results]);

  useEffect(() => {
    fetchResults().then(() => {
      setFetchingResults(false);
    });
  }, [fetchResults]);

  useEffect(() => {
    fetchEvent().then(() => {
      setFetchingEvent(false);
    });
  }, [fetchEvent]);

  useEffect(() => {
    fetchMeet().then(() => {
      setFetchingMeet(false);
    });
  }, [fetchMeet]);

  useEffect(() => {
    fetchClubRecords().then(() => {
      setFetchingClubRecords(false);
    });
  }, [fetchClubRecords]);

  useEffect(() => {
    if (selectedEvent) {
      if (selectedEvent.publishingStatus === 'ClubMembers')
        setResultType('Published');
      else setResultType('Pending');
    }
  }, [selectedEvent]);

  const publishPendingResult = async () => {
    /**call the api */
    await updateAggEventStatus({
      clubId,
      meetId,
      eventType,
      ageGroup,
      gender,
      roundType,
      newPublishingStatus: 'ClubMembers',
    });
    dispatch(eventStatusUpdate('published'));
    navigate(routes.admin.events.url(clubId, meetId));
  };

  const handleRoundOptionChange = async (name, value) => {
    const round = roundTypeOptions.find((r) => r.value === value);
    if (!round) {
      return;
    }
    setRoundType(round.type);
    setOptionValues({ ...optionValues, [name]: value });
    let results = [];
    setFetchingResults(true);
    if (value === 'all' || value === '') {
      if (eventType === 'High Jump') {
        results = await getHighJumpResults(
          clubId,
          meetId,
          eventType,
          ageGroup,
          gender,
          round.type
        );
      } else {
        results = await getResultsByRound(
          clubId,
          meetId,
          eventType,
          ageGroup,
          gender,
          round.type
        );
      }
    } else {
      if (eventType === 'High Jump') {
        results = await getHighJumpResultsByEventId(clubId, meetId, value);
      } else {
        results = await getResultsByEventId(clubId, meetId, value);
      }
    }
    setResults(resultsToTableData(results, eventType));
    setFetchingResults(false);
  };

  return (
    <>
      <MetaTags title="SplitFast | Results" />
      <AdminTablePageLayout
        loading={
          fetchingMeet ||
          // fetchingResults ||
          fetchingEvent ||
          fetchingClubRecords
        }
      >
        <AdminPageHeader
          title={
            eventType && ageGroup && gender
              ? `${gender} ${ageGroup} ${eventType}`
              : 'Results'
          }
          suffix={`- ${resultType} Results`}
          showNumber={false}
          numberText={`${results.length} Published, 0 Pending`}
          name="Result"
          buttonLabel={meet ? `Back to ${meet?.meetName}` : 'Go to event'}
          buttonStyle={{
            color: '#24282a',
            fontWeight: 600,
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            height: 'unset',
          }}
          handleButton={() => {
            dispatch(eventStatusUpdate(resultType.toLowerCase()));
            navigate(routes.admin.events.url(clubId, meetId));
          }}
          style={{
            marginBottom: 0,
          }}
        />
        {clubRecords?.map((clubRecord, index) => (
          <div className={styles.subtitle} key={index}>
            Club record: {clubRecord?.athleteName}{' '}
            {[
              'Discus',
              'Javelin',
              'Shot Put',
              'High Jump',
              'Long Jump',
              'Triple Jump',
            ].indexOf(eventType) > -1
              ? `${parseFloat(clubRecord?.recordValue).toFixed(2)} m`
              : `${convertMillisecondsToRecord(clubRecord?.recordValue, 2)}`}{' '}
            - {convertDateTime(clubRecord?.achievedAt).date}
          </div>
        ))}

        <ResultPageOptionButton
          showPublishButton={resultType === 'Pending'}
          buttonDisabled={!isPopupReady}
          handlePublishResult={publishPendingResult}
          handleEditResult={openEditPopup}
        />

        <ResultDataTable
          clubRole={clubRole}
          dataTypeOptions={roundTypeOptions}
          optionValues={optionValues}
          handleOptionChange={handleRoundOptionChange}
          results={results}
          eventType={eventType}
        />
      </AdminTablePageLayout>
      {clubRole !== 'Member' && (
        <ResultEditPopup
          resultType={resultType}
          results={results}
          eventID={eventId}
          clubID={clubId}
          meetID={meetId}
          eventType={eventType}
          ageGroup={ageGroup}
          gender={gender}
          roundType={roundType}
          title={
            eventType && ageGroup && gender && roundType
              ? `Results for ${gender} ${ageGroup} ${eventType}`
              : 'Results'
          }
          subtitle={meet ? convertDateTime(meet?.meetDate || '').date : ''}
          showPopup={showEditPopup && results.length > 0}
          closePopup={closeEditPopup}
          refreshResults={fetchResults}
        />
      )}
    </>
  );
};

Results.propTypes = {
  isOpenEditPopup: PropTypes.bool,
};

export default Results;
