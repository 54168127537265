import React from 'react';
import PropTypes from 'prop-types';
import AdminDataTable from 'components/admin/AdminDataTable';
import ResultTableTab from 'components/admin/result/ResultTableTab';
import { resultColumns, resultAttemptsCount } from 'config/admin/result';

const ResultDataTable = ({
  clubRole,
  dataTypeOptions = [],
  optionValues,
  handleOptionChange,
  results,
  eventType,
}) => {
  return results.length ? (
    <>
      <ResultTableTab
        clubRole={clubRole}
        dataTypeOptions={dataTypeOptions}
        optionValues={optionValues}
        handleOptionChange={handleOptionChange}
      />
      <AdminDataTable
        borderType="full"
        isAddedFeatures={false}
        columns={resultColumns(results)}
        data={results}
        searchInputPlaceholder=""
        headStyle={{
          gridTemplateColumns:
            results[0]?.unit === 'm' || eventType === 'High Jump'
              ? `1fr 4fr 2fr 2fr 2fr 2fr 2fr ${2 * resultAttemptsCount(results)}fr`
              : '2fr 4fr 3fr 3fr 3fr 3fr 3fr 4fr',
          background: '#f1f1f2',
        }}
        rowStyle={{
          gridTemplateColumns:
            results[0]?.unit === 'm' || eventType === 'High Jump'
              ? `1fr 4fr 2fr 2fr 2fr 2fr 2fr ${2 * resultAttemptsCount(results)}fr`
              : '2fr 4fr 3fr 3fr 3fr 3fr 3fr 4fr',
        }}
      />
    </>
  ) : null;
};

ResultDataTable.propTypes = {
  clubRole: PropTypes.string,
  dataTypeOptions: PropTypes.arrayOf(PropTypes.object),
  optionValues: PropTypes.object,
  handleOptionChange: PropTypes.func,
  results: PropTypes.array.isRequired,
  eventType: PropTypes.string,
};

export default ResultDataTable;
