import React from 'react';
import PropTypes from 'prop-types';
import styles from './ResultTableTab.module.css';

const ResultTableTab = ({
  clubRole,
  dataTypeOptions = [],
  optionValues,
  handleOptionChange,
}) => {
  return (
    <div className={styles.options}>
      {dataTypeOptions.map(
        (option) =>
          (!clubRole ||
            !option.allowedRoles ||
            option.allowedRoles.indexOf(clubRole) !== -1) && (
            <div
              key={option.value}
              className={`${styles.option} ${optionValues['dataType'] === option.value ? styles.active : ''}`}
              onClick={() => handleOptionChange('dataType', option.value)}
            >
              {option.label}
            </div>
          )
      )}
    </div>
  );
};

ResultTableTab.propTypes = {
  clubRole: PropTypes.string,
  dataTypeOptions: PropTypes.arrayOf(PropTypes.object),
  optionValues: PropTypes.object,
  handleOptionChange: PropTypes.func,
};

export default ResultTableTab;
