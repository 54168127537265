import React from 'react';
import PropTypes from 'prop-types';
import styles from './BasicPageOptionButton.module.css';

const BasicPageOptionButton = ({ optionChild, buttonChild }) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>{optionChild}</div>
      <div className={styles.rightContainer}>{buttonChild}</div>
    </div>
  );
};

BasicPageOptionButton.propTypes = {
  optionChild: PropTypes.element,
  buttonChild: PropTypes.element,
};

export default BasicPageOptionButton;
