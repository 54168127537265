import React from 'react';
import PropTypes from 'prop-types';
import CustomButton, {
  defaultButtonStyle,
} from 'components/common/CustomButton';

const ResultPageButton = ({
  showPublishButton,
  buttonDisabled,
  handlePublishResult,
  handleEditResult,
}) => {
  return (
    <>
      {showPublishButton && (
        <CustomButton
          disabled={buttonDisabled}
          style={{
            ...defaultButtonStyle,
            marginBottom: 0,
            height: 'unset',
            padding: '10px 20px',
            maxWidth: 'max-content',
            backgroundColor: 'gray',
            marginRight: 10,
          }}
          onClick={handlePublishResult}
        >
          Publish Results
        </CustomButton>
      )}
      <CustomButton
        disabled={buttonDisabled}
        style={{
          ...defaultButtonStyle,
          marginBottom: 0,
          height: 'unset',
          padding: '10px 20px',
        }}
        onClick={handleEditResult}
      >
        Edit Results
      </CustomButton>
    </>
  );
};

ResultPageButton.propTypes = {
  showPublishButton: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  handlePublishResult: PropTypes.func,
  handleEditResult: PropTypes.func,
};

export default ResultPageButton;
