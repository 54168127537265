import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react'; // Using Lucide icons (optional)
import styles from './TableHeaderSelect.module.css'; // Create styles for styling
import PropTypes from 'prop-types';

const TableHeaderSelect = ({ options, handleOptionSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSelect = (option) => {
    setIsOpen(false);
    handleOptionSelect(option);
  };

  return (
    <div className={styles.selectContainer}>
      <div className={styles.selectBox} onClick={toggleDropdown}>
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </div>
      {isOpen && (
        <ul className={styles.optionsList}>
          {options.map((option) => (
            <li
              key={option.value}
              className={styles.optionItem}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

TableHeaderSelect.propTypes = {
  options: PropTypes.object,
  selectedOption: PropTypes.bool,
  handleOptionSelect: PropTypes.func,
};

export default TableHeaderSelect;
