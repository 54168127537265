import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ResultsExportTable.module.css';

import TableFilter from 'components/admin/TableFilter';
// import { ReactComponent as SearchIcon } from 'assets/images/icon_search.svg';
// import { ReactComponent as FilterIcon } from 'assets/images/icon_filter.svg';
import CustomCheckbox from 'components/common/CustomCheckbox';
import { ReactComponent as SortIcon } from 'assets/images/icon_sort.svg';
import { RESULTS_EXPORT_SELECT_OPTIONS } from 'constants';
import TableHeaderSelect from '../TableHeaderSelect';

const ResultsExportTable = ({
  data = [],
  isAddedFeatures = true,
  columns,
  headStyle,
  rowStyle,
  tableStyle,
  // searchInputPlaceholder,
  borderType = 'default',
  isShadow = true,
  title = 'Results',
  handleCheckBoxValueChange,
  allSelected = false,
  handleAllCheckBoxValueChange,
  handleOptionSelect,
}) => {
  const [searchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filters, setFilters] = useState({});
  const [showFilterPopup, setShowFilterPopup] = useState(false);

  useEffect(() => {
    const generatedFilters = {};
    columns.forEach((column) => {
      if (column.filterable) {
        generatedFilters[column.accessor] = {
          label: column.label,
          values: [
            ...new Set(
              data
                .map((row) => row[column.accessor])
                .filter((value) => value !== undefined && value !== null)
            ),
          ],
          selectedValue: [],
        };
      }
    });
    setFilters(generatedFilters);
  }, [data, columns]);

  const filteredData = data.filter((row) => {
    const matchesSearch = columns.some((column) => {
      const cellValue = row[column.accessor];
      return (
        !cellValue ||
        (cellValue &&
          cellValue
            .toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase()))
      );
    });

    const matchesFilters = Object.keys(filters).every((key) => {
      const filterValue = filters[key].selectedValue;
      return (
        !filterValue ||
        !filterValue.length ||
        filterValue.includes(row[key]?.toString()) ||
        filterValue.includes(row[key])
      );
    });

    return matchesSearch && matchesFilters;
  });

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      return { key, direction: 'asc' };
    });
  };

  const handleFilterChange = (filters) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      Object.keys(newFilters).forEach((key) => {
        if (newFilters[key]) {
          newFilters[key].selectedValue = filters[key] || [];
        }
      });
      return newFilters;
    });
  };

  return (
    <>
      <div
        className={styles.athletesTableWrapper}
        style={isShadow ? null : { boxShadow: 'unset' }}
      >
        <div className={styles.athletesTable} style={tableStyle}>
          {isAddedFeatures ? (
            <div className={styles.athletesTableHeader}>
              <div className={styles.inputWrapper}>
                {/* <input
                  type="text"
                  placeholder={searchInputPlaceholder}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <SearchIcon /> */}
              </div>
              {/* {Object.keys(filters).length > 0 && (
                <div
                  className={styles.filterWrapper}
                  onClick={() => setShowFilterPopup(true)}
                >
                  <div className={styles.filterText}>Filter</div>
                  <div className={styles.filterImage}>
                    <FilterIcon />
                  </div>
                </div>
              )} */}
            </div>
          ) : null}
          <div
            className={`${styles.athletesRows} ${borderType === 'full' ? styles.fullBorder : null}`}
            style={borderType === 'none' ? { border: 'none' } : null}
          >
            <div className={styles.athletesRow} style={headStyle}>
              {columns.map((column, index) => {
                return (
                  <div
                    className={styles.headerCell}
                    style={column.headerStyle}
                    onClick={() =>
                      column.sortable && handleSort(column.accessor)
                    }
                    key={index}
                  >
                    {index === 0 && (
                      <div className={styles.checkboxCell}>
                        <CustomCheckbox
                          label=""
                          checked={allSelected}
                          style={{ margin: 0 }}
                          onChange={() => handleAllCheckBoxValueChange()}
                        />
                        <TableHeaderSelect
                          options={RESULTS_EXPORT_SELECT_OPTIONS}
                          handleOptionSelect={handleOptionSelect}
                        ></TableHeaderSelect>
                      </div>
                    )}
                    {column.checkbox ? (
                      <div className={styles.headerCheckbox}></div>
                    ) : null}
                    {column.label}
                    {column.sortable ? (
                      <div className={styles.headerSortImage}>
                        <SortIcon
                          style={{
                            cursor: 'pointer',
                            transform:
                              sortConfig.key === column.accessor
                                ? sortConfig.direction === 'asc'
                                  ? 'rotate(180deg)'
                                  : 'rotate(0deg)'
                                : 'rotate(0deg)',
                            fill: '#000',
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            {sortedData.map((row, i) => (
              <div key={i} className={styles.athletesRow} style={rowStyle}>
                {columns.map((column, i) => (
                  <div
                    key={i}
                    className={styles.childCell}
                    style={column.style}
                  >
                    {column.checkbox ? (
                      <div className={styles.bodyCheckbox}></div>
                    ) : null}
                    {column.checkboxEnable && (
                      <CustomCheckbox
                        label=""
                        checked={row.isCheck}
                        style={{ margin: 0 }}
                        onChange={() => handleCheckBoxValueChange(row)}
                      />
                    )}
                    {row[column.accessor]}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <TableFilter
        filters={filters}
        title={title}
        showPopup={showFilterPopup}
        closePopup={() => setShowFilterPopup(false)}
        handleFilterChange={handleFilterChange}
      />
    </>
  );
};

ResultsExportTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  headStyle: PropTypes.object,
  rowStyle: PropTypes.object,
  tableStyle: PropTypes.object,
  searchInputPlaceholder: PropTypes.string,
  isAddedFeatures: PropTypes.bool,
  borderType: PropTypes.string,
  isShadow: PropTypes.bool,
  isEditable: PropTypes.bool,
  handleValueChange: PropTypes.func,
  openConfirmPopup: PropTypes.func,
  primaryField: PropTypes.string,
  title: PropTypes.string,
  handleCheckBoxValueChange: PropTypes.func,
  handleAllCheckBoxValueChange: PropTypes.func,
  allSelected: PropTypes.bool,
  handleOptionSelect: PropTypes.func,
};

export default ResultsExportTable;
