import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  eventStatus: 'published',
  selectedEvent: null,
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    eventStatusUpdate: (state, action) => {
      state.eventStatus = action.payload;
    },
    updateSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
  },
});

export const { eventStatusUpdate, updateSelectedEvent } = eventSlice.actions;
export default eventSlice.reducer;
