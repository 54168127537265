import React from 'react';
import PropTypes from 'prop-types';
import BasicPageOptionButton from 'components/admin/basic/BasicPageOptionButton';
import ResultPageButton from 'components/admin/result/ResultPageButton';

const ResultPageOptionButton = ({
  showPublishButton,
  buttonDisabled,
  handlePublishResult,
  handleEditResult,
}) => {
  return (
    <BasicPageOptionButton
      buttonChild={
        <ResultPageButton
          showPublishButton={showPublishButton}
          buttonDisabled={buttonDisabled}
          handlePublishResult={handlePublishResult}
          handleEditResult={handleEditResult}
        />
      }
    />
  );
};

ResultPageOptionButton.propTypes = {
  showPublishButton: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  handlePublishResult: PropTypes.func,
  handleEditResult: PropTypes.func,
};

export default ResultPageOptionButton;
